<template>
  <div>
    <div class="header">
      <img class="bg" src="/img/otherBg.png" alt="" />
      <div class="content">
        <div>
          <div class="title">联系我们</div>
          <div class="desc">用科技创造未来 /<br />循环和再生，让地球更年轻</div>
        </div>
      </div>
    </div>
    <div class="first hidden-sm-and-down">
      <div class="content">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <p class="title">请留下您的联系方式或<br />马上联系我们的销售人员</p>
            <p class="desc">
              如果您需要一份产品报价，或者想了解更多产品授权、部署详情，请留下您的联系方式。我们的销售团队将在第一时间与您联系。
            </p>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="contact">
              <p>宁波蔚澜环保科技有限公司</p>
              <span>地址：浙江省宁波市鄞州区明曙路810号</span>
              <span>邮编：315000</span>
              <span>联系电话：400 0574 168（周一至周日 8:30-22:00）</span>
              <img src="/img/contact.png" alt="" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="first hidden-md-and-up">
      <div class="content">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="contact">
              <p>宁波蔚澜环保科技有限公司</p>
              <span>地址：浙江省宁波市鄞州区明曙路810号</span>
              <span>邮编：315000</span>
              <span>联系电话：400 0574 168（周一至周日 8:30-22:00）</span>
              <img src="/img/contact.png" alt="" />
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <p class="title">请留下您的联系方式或<br />马上联系我们的销售人员</p>
            <p class="desc">
              如果您需要一份产品报价，或者想了解更多产品授权、部署详情，请留下您的联系方式。我们的销售团队将在第一时间与您联系。
            </p>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="second">
      <div>
        <el-form :model="form" ref="form" :rules="rules">
        <el-row>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-row :gutter="30">
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="item">
                    <el-form-item label="" prop="name">
                      <p class="label">姓名</p>
                      <el-input placeholder="请输入您的姓名" v-model="form.name" maxlength="10" class="input"></el-input>
                    </el-form-item>  
                  </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="item">
                      <el-form-item label="" prop="email">
                        <p class="label">邮箱</p>
                        <el-input placeholder="请输入您的邮箱" v-model="form.email" maxlength="30" class="input"></el-input>
                      </el-form-item>  
                  </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="item">
                      <el-form-item label="" prop="phone">
                        <p class="label">电话</p>
                        <el-input placeholder="请输入您的电话" v-model="form.phone" maxlength="11" class="input"></el-input>
                      </el-form-item>
                      
                  </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                  <div class="item">
                      <el-form-item label="" prop="company">
                        <p class="label">公司</p>
                        <el-input placeholder="请输入您的公司的名称" v-model="form.company" maxlength="30" class="input"></el-input>
                      </el-form-item>
                      
                  </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <div class="item">
                      <el-form-item label="" prop="detail">
                        <p class="label">详情</p>
                        <el-input type="textarea" placeholder="您可以简单描述一下您的需求" v-model="form.detail" maxlength="200" class="input"></el-input>
                      </el-form-item>
                      <br><br>
                      <el-button @click="submit" style="padding:21px 70px;border-radius:60px;background-color: #0670D8;font-weight: 200;font-size:2.6rem" type="primary" round>提交</el-button>
                  </div>
              </el-col>
              </el-row>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div style="width:1px;height:1px;"></div>
          </el-col>
        </el-row>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {setFeedback} from '@/api'
export default {
  data() {
    function checkPhone(rule, value, callback) {
      if (!value) {
        return callback();
      } else {
        const reg = /^[1][0-9]{10}$/
        if (reg.test(value)) {
          return callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    }
    return {
       form: {
         name: null,
         phone: null,
         email: null,
         company: null,
         detail: null
       },
       rules: {
        name: [{ required: true, message: "必填", trigger: "blur" }],
        phone: [
          { required: true, message: "必填", trigger: "blur" },
          {validator: checkPhone, trigger: 'blur'}
        ],
        email: [
          { required: true, message: "必填", trigger: "blur" },
          {
            validator:function(rule, value, callback) {
              let reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              if (!reg.test(value)) return callback(new Error('邮箱错误'))
              return callback()
            },
            trigger: 'blur'
          }
        ],
        company: [{ required: true, message: "必填", trigger: "blur" }],
        detail: [{ required: true, message: "必填", trigger: "blur" }],
       }
    };
  },
  methods: {
    async submit() {
      try{
         await this.$refs.form.validate();
      } catch(e) {
        return;
      }
      
      let data = { ...this.form };
      await setFeedback(data)
      this.$message.success("我们收到了！谢谢您")
    }
  }
};
</script>
<style>
.input input,.input  textarea{
            background: #E9E9E9;
            border-radius: 8px;
            outline: none;
            border:none;
            font-size: 18px;
            line-height: 2;
            letter-spacing: 1px;
            color: #000;
            padding: 2rem 3.2rem;
            box-sizing: border-box;
            width: 100%;
            height: auto;
            font-weight: 200;
}
.input input::-webkit-input-placeholder, .input  textarea::-webkit-input-placeholder {  color :  #ABA9A9 ; }
.input input::-moz-placeholder, .input  textarea::-moz-placeholder {  color :  #ABA9A9 ; }
.input input::-ms-input-placeholder, .input  textarea::-ms-input-placeholder {  color :  #ABA9A9 ; }
</style>
<style scoped lang="scss">
@import "./header.scss";
.first {
  width: 100%;
  background-color: #fff;
  padding: 11rem 0 9rem;
  position: relative;
  .content {
    width: 80%;
    max-width: 1520px;
    margin: auto;
    position: relative;
    .title{
        font-size: 4rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #252525;
        font-weight: 200;
    }
    .desc{
        font-size: 2rem;
        line-height: 1.5;
        letter-spacing: 1px;
        color: #000000;
        margin-top: 5rem;
        font-weight: 200;
    }
    .contact {
      position: absolute;
      z-index: 99999999;
      top: -28vw;
      right: 0;
      background: #0670d8;
      text-align: left;
      padding: 4rem;
      box-sizing: border-box;
      width: 66rem;
      p {
        font-size: 3rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #ffffff;
        margin-bottom: 4rem;
        font-weight: 200;
      }
      span {
        display: block;
        font-size: 2rem;
        line-height: 2;
        letter-spacing: 1px;
        color: #ffffff;
        font-weight: 200;
      }
      img {
        width: 100%;
        margin-top: 7rem;
      }
    }
  }
}
.second {
  background: #f7f7f7;
  padding: 4.3rem 0 6rem;
  & > div {
    width: 80%;
    max-width: 1520px;
    margin: 0 auto;
    .item{
      .label{
        font-size: 2rem;
        line-height: 1.2;
        letter-spacing: 1px;
        color: #252525;
        padding: 1.5rem 0;
        padding-left: 3.2rem;
        box-sizing: border-box;
      }
      margin-bottom: 4.7rem;
    }
  }
}

@media screen and (max-width: 992px) {
    .first .content{
        .title,.desc{
            text-align: center;
        }

    }
    .first .content .contact{
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto 20px;
        margin-top: 4rem;
        width: 100%;
        max-width: 66rem;
    }
}
</style>
